// MainWrapper.js
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Welcome from './Welcome'; // Import the Welcome component
import './Wrapper-style.css';
import SellWrapper from './SellWrapper';
import HowTo from './HowTo';
import NavBar from './NavBar';

const MainWrapper = () => {
  

  return (
    <Container className='mainwrapper-container' maxWidth="xl">
      <NavBar></NavBar>
      <Grid className='mainwrapper-childcontainer' container spacing={2}>
        {/* Left side (66% width) */}
        <Grid item xs={8}>
          {/* Use the Welcome component */}
          <Welcome />
          <SellWrapper />
        </Grid>

        {/* Right side (33% width) */}
        <Grid item xs={4}>
          {/* Your content for the right side goes here */}
          <HowTo></HowTo>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainWrapper;
