import React from 'react';
//import Nav from './Nav';
// import NavBar from './NavBar';
import MainWrapper from './MainWrapper';
import { Helmet } from 'react-helmet';

const App = () => {

  document.documentElement.style.overflowY = 'hidden';
  document.body.style.overflowY = 'hidden';
  
  return (
    <div>
      <Helmet>
        <script src="https://code.tidio.co/6td09lewwtfxfamrun6hxojzvavrk0ut.js" async=""></script>
      </Helmet>
      {/* <NavBar></NavBar> */}
      <MainWrapper></MainWrapper>
      
      
    </div>
  );
};

export default App;
