// NavBarToolbar.js

import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import axios from 'axios';
import LoginButton from './LoginButton';
import ExitDoorIcon from './ExitDoorIcon';

export default function NavBarToolbar() {
  const [steamData, setSteamData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if steamID and personaname are in local storage
        const storedSteamId = localStorage.getItem('steamId');
        const storedPersonaname = localStorage.getItem('personaname');
        const storedAvatarurl = localStorage.getItem('avatarurl');

        if (storedSteamId && storedPersonaname && storedAvatarurl) {
          // If both steamID and personaname are in local storage, set them in the component state
          setSteamData({ steamId: storedSteamId, personaname: storedPersonaname, avatarurl: storedAvatarurl });
        } else {
          // If steamID or personaname is not in local storage, check if there's a steamid param in the URL
          const urlParams = new URLSearchParams(window.location.search);
          const urlSteamId = urlParams.get('steamid');

          if (urlSteamId) {
            // If steamid param is in the URL, make a request to the serverless function endpoint
            const urlWithoutSteamId = window.location.origin + window.location.pathname;
            window.history.replaceState({}, document.title, urlWithoutSteamId);

            const response = await axios.get(`https://api.skinsauna.gg/.netlify/functions/getProfile?steamid=${urlSteamId}`);

            // Extract personaname from the response
            const personaname = response.data.response.players[0].personaname;
            const avatarurl = response.data.response.players[0].avatarfull;

            // Save steamID and personaname to local storage
            localStorage.setItem('steamId', urlSteamId);
            localStorage.setItem('personaname', personaname);
            localStorage.setItem('avatarurl', avatarurl);

            // Set the fetched data in the component state
            setSteamData({ steamId: urlSteamId, personaname, avatarurl });
            window.location.href = "https://sell.skinsauna.gg";
          }
        }
      } catch (error) {
        console.error('Error fetching Steam data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        height: '100%', // Adjust the height as needed
      }}
    >
      <Stack direction="row" spacing={2}>
        {steamData ? (
          <Avatar alt={steamData.personaname} src={steamData.avatarurl} />
        ) : (
          <LoginButton />
        )}
        <ExitDoorIcon></ExitDoorIcon>
      </Stack>
    </Container>
  );
}
