import React from 'react';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const ExitDoorIcon = () => {
  const handleExitClick = () => {
    // Purge or clear local storage
    localStorage.clear();
    window.location.reload();
  };

  return (
    <IconButton onClick={handleExitClick} style={{ color: 'white' }}>
      <ExitToAppIcon />
    </IconButton>
  );
};

export default ExitDoorIcon;
