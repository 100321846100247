
// SellWrapper.js
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import './Wrapper-style.css';
import Inventory from './Inventory';
import Button from '@mui/material/Button';
import './mabryfont/grotesk.css';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectedItemCard from './SelectedItemsCard';


const SellWrapper = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectItem = (item) => {
    if (!selectedItems.some(selectedItem => selectedItem.name === item.name)) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    }
  };
  
  const handleRemoveItem = (itemToRemove) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item) => item.name !== itemToRemove.name)
    );
  };

  const handleRefresh = () => {
    // Reload the page when the refresh button is clicked
    window.location.reload();
  };

  const handleSellButtonClick = () => {
    // Extract names of all selected items
    const allItemNames = selectedItems.map(item => item.market_hash_name);

    // Concatenate names into one message
    const sellmsg = `Myynti-ilmoitus: ${allItemNames.join(', ')}`;

    // Log the concatenated message
    console.log(selectedItems);

    /* eslint-disable */
    window.tidioChatApi.messageFromVisitor(sellmsg);

    setSelectedItems([]);
    /* eslint-enable */
  };

  const buttonBackgroundColor = selectedItems.length === 0 ? 'rgba(0, 0, 0, 0.51)' : '#16a34a';

  return (
    <Container className='sellwrapper-container'>
      <Grid container spacing={0} style={{ height: '100%' }}>
        {/* Top row (10% height) */}
        <Grid item xs={8}>
          {/* First column (66% width) */}
          <div className='yourinvtext-container'>
            <h2 className='yourinvtext' style={{ fontFamily: 'Mabry Pro, sans-serif' }}>Tavaraluettelosi
              <IconButton onClick={handleRefresh} color="primary" style={{ transform: 'translateY(-4px)' }}>
                <RefreshIcon />
              </IconButton>
            </h2>
            
          </div>
        </Grid>

        {/* Top row (10% height) */}
        <Grid item xs={4}>
          {/* Second column (33% width) */}
          <div className='tosell-container' style={{ borderLeft: '1px solid black' }}>
            <h2 className='toselltext' style={{ fontFamily: 'Mabry Pro, sans-serif', fontWeight: '700' }}>Myytäväksi</h2>
          </div>
        </Grid>

        {/* Second row (90% height) */}
        <Grid item xs={8} className='inv-container' style={{ borderRight: '1px solid black', borderRadius: '0 0 0 15px', height: '69vh', background: 'rgba(0, 0, 0, 0.67)', backdropFilter: 'blur(3px)' }}>
          {/* First column (66% width) */}
          <div style={{ padding: 15 }}>
            <Inventory onSelect={handleSelectItem} selectedItems={selectedItems} />

          </div>
        </Grid>

        {/* Second row (90% height) */}
        <Grid item xs={4} >
          <div style={{ borderLeft: '1px solid black', background: 'rgba(0, 0, 0, 0.51)', borderRadius: '0 0 15px 0', backdropFilter: 'blur(1px)' }}>
            <div className='sell-container'>
        
              {selectedItems.map((item, index) => (
                <SelectedItemCard key={index} item={item} onRemove={handleRemoveItem} />
              ))}

              {/* Button to sell selected items */}
              
            </div>

            {/* Empty bottom row */}
            <div style={{ display: 'grid', height: '55px' }}>
              <Button variant="contained" style={{
                backgroundColor: buttonBackgroundColor, fontFamily: 'PowerGrotesk-UltraBold', borderBottomRightRadius: '15px' }} onClick={handleSellButtonClick} disabled={selectedItems.length === 0}>
                Arvioi skinit
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SellWrapper;