// Welcome.js
import React from 'react';
import Container from '@mui/material/Container';
import './Wrapper-style.css';
import './mabryfont/grotesk.css';

const Welcome = () => {
  // Check if 'personaname' exists in local storage
  const personaname = localStorage.getItem('personaname');

  return (
    <Container className='welcome-container'>
      {/* Your content for the Welcome component goes here */}
      {personaname ? (
        <h1 style={{ color: 'whitesmoke', fontFamily: 'PowerGrotesk-UltraBold', paddingLeft: '10px' }}>{`Tervetuloa, ${personaname}!`}</h1>
      ) : (
          <h1 style={{ color: 'whitesmoke', fontFamily: 'PowerGrotesk-UltraBold', paddingLeft: '10px' }}>Rennoin tapa myydä skinisi ☁️</h1>
      )}
    </Container>
  );
};

export default Welcome;
