// Step.js

import React from 'react';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import './mabryfont/grotesk.css'; // Import Typography component
import './mabryfont/stylesheet.css';
import './step.css';

const Step = ({ stepNumber, image, title, description }) => {

  let avatarSize = { width: 76, height: 76 };

  // Adjust size based on stepNumber
  if (stepNumber === 4) {
    avatarSize = { width: 56, height: 56, marginLeft: '8px' }; // Adjust the size for stepNumber 4
  }

  return (
    <Card className='stepCard' style={{ width: '425px', borderRadius: '15px', backgroundColor: '#00000099', backdropFilter: 'blur(3px)', marginTop: stepNumber === 4 ? '6vh' : '10px', }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={`Step ${stepNumber}`} src={image} sx={avatarSize} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h6" style={{ color: 'whitesmoke', fontFamily: 'PowerGrotesk-UltraBold' }}>
              {title}
            </Typography>
          }
          secondary={
            <Typography variant="body2" style={{ color: '#c4c4c4', fontFamily: 'Mabry Pro, sans-serif' }}>
              {description}
            </Typography>
          }
          sx={{ paddingLeft: '15px' }}
        />
      </ListItem>
    </Card>
  );
};

export default Step;
