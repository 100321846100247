// SiteLogo.js
import React from 'react';
import skinsaunaLogo from './skinsauna-logo.png';
import './NavBar-style.css';

const SiteLogo = () => {
  return (
    <div>
      <a href="https://skinsauna.gg/fi" rel="noopener noreferrer">
        <img
          src={skinsaunaLogo}
          alt="SkinSauna Logo"
          className='sitelogo'
          style={{ filter: 'drop-shadow(2px 4px 6px black)', cursor: 'pointer' }}
        />
      </a>
    </div>
  );

};

export default SiteLogo;
