import * as React from 'react';
import Container from '@mui/material/Container';
import './NavBar-style.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NavBarToolbar from './NavBarToolbar'; 
import SiteLogo from './SiteLogo';
// import GiveawayLogo from './GiveawayLogo';



export default function NavBar() {
  return (
    <Container className="navbar-container">
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <Grid container spacing={2} justifyContent="space-between" height="100%">
          <Grid item xs={3}>
            {/* <SiteLogo></SiteLogo> */}
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            {/* <GiveawayLogo></GiveawayLogo> */}
            <SiteLogo></SiteLogo>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'right' }}>
            <NavBarToolbar />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
