import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const TeaseCard = ({ market_hash_name, image_url }) => {
  return (
    <Card className="card" style={{ maxWidth: '25%', background: 'radial-gradient(circle, #434343 0%, black 100%)', color: '#c4c4c4' }}>
      <CardMedia
        component="img"
        alt={market_hash_name}
        height="100"
        image={image_url}
      />
      <CardContent style={{ padding: '0px', backgroundColor: 'inherit', color: 'inherit' }}>
        <Typography variant="h6" component="div" style={{ fontSize: '15px', textAlign: 'center', fontFamily: 'Mabry Pro, sans-serif' }}>
          {market_hash_name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TeaseCard;
