import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSteam } from "@fortawesome/free-brands-svg-icons";
import './mabryfont/grotesk.css';
import './mabryfont/stylesheet.css'; // Adjust the path based on your project structure
import './login.css';

export default function LoginButton() {


  
  
  const buttonStyle = {
    backgroundColor: '#16A34A',
    height: '40px',
    paddingLeft: '10px',
    paddingRight: '10px'
  };

  const loginIconStyle = {
    height: '25px',
    margin: 'auto',
  };

  const loginTextStyle = {
    fontWeight: '900',
    fontSize: '16px',
    marginTop: '1px',
    fontFamily: 'PowerGrotesk-UltraBold',
    textTransform: 'none'
  };

  return (
    <a href="https://steamcommunity.com/openid/login?openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.realm=http://api.skinsauna.gg/steam/authenticate/&openid.return_to=http://api.skinsauna.gg/steam/authenticate/">
      <Button variant="contained" style={buttonStyle}>
        <Stack direction="row" spacing={1}>
          <FontAwesomeIcon icon={faSteam} style={loginIconStyle} />
          <Typography variant="button" style={loginTextStyle} className='loginfont'>
            Kirjaudu sisään
          </Typography>
        </Stack>
      </Button>
    </a>
  );
}
