import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ItemCard from './ItemCard';
import SignInPersuasion from './SignInPersuasion';



const Inventory = ({ items: inventoryItems, onSelect, selectedItems }) => {
  const [fetchedItems, setFetchedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const steamId = localStorage.getItem('steamId');

        // Check if steamId is available
        if (!steamId) {
          setLoading(false);
          return;
        }

        try {
          const response = await fetch(`https://api.steamapis.com/steam/inventory/${steamId}/730/2?api_key=QJa2mVRnaUZM_1Xt2LyqL8-jlos`);

          if (response.status === 403) {
            // Handle 403 status code (Forbidden)
            setErrorMessage('Ongelma: Steam-profiilisi tai tavaraluettelosi on yksityinen. <br>Vaihda profiiliasetuksistasi tavaraluettelosi julkiseksi. <br><br>Jos tarvitset apua, ota yhteyttä meihin painamalla sivun oikealla alareunalla olevaa chat-painiketta ja valitse "Ongelma tavaraluettelon kanssa". <br><br>Vastaamme muutamassa minuutissa. ');


            setLoading(false);
            return;
          }

          if (response.status !== 200) {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
          }

          const data = await response.json();

          // Filter items based on marketable property
          const filteredItems = data.descriptions.filter((description) => {
            return !description.type.includes("Graffiti");
          });
          
          const itemOrder = {
            "Covert": 1,
            "Extraordinary": 1,
            "Classified": 2,
            "Restricted": 3,
            "Mil-Spec": 4,
            "Industrial": 5,
          };

          filteredItems.sort((a, b) => {
            const orderA = Object.keys(itemOrder).findIndex(type => a.type.includes(type)) + 1 || 7;
            const orderB = Object.keys(itemOrder).findIndex(type => b.type.includes(type)) + 1 || 7;

            return orderA - orderB;
          });

          





          

          setFetchedItems(filteredItems);
          setLoading(false);

          // Proceed with using filteredItems or any other logic
        } catch (error) {
          console.error(`Error fetching data: ${error.message}`);
          // Handle the error appropriately
        }
      } catch (error) {
        console.error('Error fetching inventory:', error);
        window.location.reload();
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  return (
    <div className="inventory-container">
      {!loading ? (
        <>
          {errorMessage ? (
            <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: errorMessage.replace(/\n/g, '<br />') }} />
          ) : (
            <>
              {fetchedItems.length > 0 ? (
                <>
                  <div className="cards-container">
                    {fetchedItems
                      .reduce((uniqueItems, item) => {
                        // Check if an item with the same market_hash_name is already in uniqueItems
                        const existingItem = uniqueItems.find(
                          (uniqueItem) => uniqueItem.market_hash_name === item.market_hash_name
                        );

                        // If not found, add the item to uniqueItems
                        if (!existingItem) {
                          uniqueItems.push(item);
                        }

                        
                        return uniqueItems;
                      }, [])
                      .map((uniqueItem, index) => (
                        <ItemCard
                          key={index}
                          item={uniqueItem}
                          onSelect={() => onSelect(uniqueItem)}
                          isSelected={selectedItems.some(
                            (selectedItem) => selectedItem.name === uniqueItem.name
                          )}
                        />
                      ))}
                  </div>
                </>
              ) : (
                <SignInPersuasion></SignInPersuasion>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <CircularProgress />
          <h5 style={{ color: 'whitesmoke', fontFamily: 'Mabry Pro, sans-serif' }}>
              Lataako edelleen 30 sekunnin jälkeen? Yritä päivittää sivu... <br></br><br></br>  
              Jos ongelma ei ratkea, ota yhteyttä meihin painamalla sivun oikealla alareunalla olevaa chat-painiketta ja valitse "Ongelma tavaraluettelon kanssa".<br></br><br></br>  Vastaamme muutamassa minuutissa.
          </h5>
          {/* Add more Skeleton components as needed */}
        </>
      )}
    </div>
  );
};

export default Inventory;