// SelectedItemCard.js
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import './mabryfont/stylesheet.css';

const SelectedItemCard = ({ item, onRemove }) => {
  return (
    <Card style={{ marginTop: '2%', scale: '0.95', background: 'radial-gradient(circle, #141a24 0%, #1c1c1c 100%)', color: '#c4c4c4' }}>
      <CardContent style={{ paddingBottom: '6px' }}>
        <Grid container spacing={2}>
          {/* Left Column - Image */}
          <Grid item xs={4}>
            <img
              src={`https://community.cloudflare.steamstatic.com/economy/image/${item.icon_url}`}
              alt={item.name}
              style={{ maxWidth: '80%' }}
            />
          </Grid>

          {/* Middle Column - Item Name */}
          <Grid item xs={7} style={{ maxWidth: '50%', padding: '0px', paddingTop: '15px' }}>
            <Typography variant="h6" component="div" style={{ fontSize: '1rem', fontFamily: 'Mabry Pro, sans-serif' }}>
              {item.name}
            </Typography>
          </Grid>

          {/* Right Column - Remove Button */}
          <Grid item xs={1}>
            <IconButton style={{ marginTop: '50%' }} color="error" onClick={() => onRemove(item)} >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SelectedItemCard;
