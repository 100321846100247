// HowTo.js

import React from 'react';
import Step from './Step';
import Stack from '@mui/material/Stack';
import step1Image from './step1.avif';
import step2Image from './step2.avif';
import step3Image from './step3.avif';
import alertImage from './alert.png';


const HowTo = () => {
  return (
    <div style={{ marginTop: '25px' }}>
      <h1 style={{ color: '#00000000', fontFamily: 'PowerGrotesk-UltraBold', textAlign: 'end' }}>How to Sell Skins</h1>
      <Stack spacing={1} style={{ display: 'grid', justifyContent: 'end' }}>
        <Step stepNumber={1} image={step1Image} title="Valitse skinit" description="Klikkaa myytävät skinit tavaraluettelostasi" />
        <Step stepNumber={2} image={step2Image} title="Arvioi skinit" description="Chat-ikkuna avautuu, ja tarjoamme käteistä skineistäsi" />
        <Step stepNumber={3} image={step3Image} title="Maksamme rahat heti" description="Vastaanotettuamme skinisi lähetämme rahat suoraan pankkitilillesi!" />
        <Step  stepNumber={4} image={alertImage} title="" description="Puuttuuko jokin skini tai luettelo ei lataudu? Paina alla oleva chat-nappia ja autamme!" />
      </Stack>
    </div>
  );
};

export default HowTo;
