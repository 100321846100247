import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './mabryfont/stylesheet.css';
import './Wrapper-style.css';

const extractTextInBrackets = (str) => {
  const match = str.match(/\((.*?)\)/);
  return match ? match[1] : null;
};

const ItemCard = ({ item, onSelect, isSelected }) => {
  let displayText;

  if (item.market_hash_name.includes("Sticker")) {
    displayText = "Sticker";
  } else if (item.market_hash_name.includes("Case")) {
    displayText = "Case";
  } else if (item.type.includes("Agent")) {
    displayText = "Agent";
  } else if (item.type.includes("Music Kit")) {
    displayText = "Music Kit";
  } else if (item.type.includes("Collectible")) {
    displayText = "Collectible";
  } else if (item.type.includes("Container")) {
    displayText = "Sticker";
  } else {
    displayText = extractTextInBrackets(item.market_hash_name) || item.type;
  }

  let rarityborder = '#808080';
  let raritybg = 'radial-gradient(circle at 50% 100%, rgb(129 129 129 / 22%), #141A24 60%)'

  if (item.type.includes("Classified")) {
    rarityborder = '#D52CE7';
    raritybg = 'radial-gradient(circle at 50% 100%, rgb(174 0 255 / 22%), rgb(20, 26, 36) 60%)';
  } else if (item.type.includes("Industrial")) {
    rarityborder = "#6498E0";
  } else if (item.type.includes("Mil-Spec")) {
    rarityborder = "#4C69CA";
  } else if (item.type.includes("Restricted")) {
    rarityborder = "#8A47FF";
  } else if (item.type.includes("Covert")) {
    rarityborder = "#EB4B4B";

  }
  else if (item.type.includes("Extraordinary")) {
    rarityborder = "#EB4B4B";
  }




  return (
    <Card
      className={`card ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
      style={{
        cursor: 'pointer',
        maxWidth: '25%',
        height: '165px',
        display: 'flex',
        flexDirection: 'column',
        background: raritybg,
        color: '#c4c4c4',
        borderBottom: '4px solid ' + rarityborder,
      }}
    > 

      <CardMedia
        component="img"
        alt={item.name}
        height="100"
        style={{ transform: 'scale(0.85)', marginTop:'auto' }}
        image={`https://community.cloudflare.steamstatic.com/economy/image/${item.icon_url}`}
      />
      <CardContent
        style={{
          padding: '0px',
          marginTop: 'auto', // This pushes the content to the bottom
          backgroundColor: isSelected ? '#57c170' : 'inherit',
          color: isSelected ? 'black' : 'inherit',
        }}
      >
        <Typography
          variant="h6"
          component="div"
          style={{
            fontSize: '15px',
            textAlign: 'center',
            color: 'white',
            fontFamily: 'Mabry Pro, sans-serif',
            
          }}
        >
          {isSelected ? 'Valittu' : displayText}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ItemCard;
